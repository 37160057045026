import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  mainTeaserTitle: 'With cutting-edge platform technology and a headless approach, Mitsubishi Electric Factory Automation is maximizing its digital B2B potential',
  theCompanySectionTitle: 'Mitsubishi Electric Factory Automation',
  theCompanySectionText: 'Mitsubishi Electric Factory Automation is a leading company in industrial automation. Technological support from freshcells systems engineering enables Mitsubishi Electric Factory Automation to achieve its business goals and deliver valuable features for both users and content creators through its website and headless CMS.',
  theChallengeTitle: 'Developing a Comprehensive and Flexible HCMS Infrastructure for EMEA-Wide Requirements',
  theChallengeText: '<p>As an EMEA-wide company with an extensive product range, Mitsubishi Electric Factory Automation’s web platform serves as a key touchpoint for visitors from diverse markets and industries.</p><p>Integrating a headless content management system (HCMS) to manage content across 21 countries, implementing efficient user management, and seamlessly embedding additional functions and systems presented a complex challenge. The primary goal was to fully relaunch the previous legacy platform and migrate to a sustainable, scalable solution. The HCMS enabled comprehensive content and download management, imported external product information, and made it accessible to all website users through a custom-developed search function.</p><p>Additionally, a customer relationship management (CRM) system was integrated, along with login systems and various web services. The focus was on optimizing lead generation, guiding users effectively to the content relevant to them. Furthermore, an asset management system was also incorporated.</p>',
  technicalChallengeTitle: 'Technical and Structural Challenges',
  multilingualContentTitle: 'Multilingual Content Management',
  multilingualContentText: 'Managing content across multiple languages and markets, along with establishing a detailed content inheritance logic, presented significant challenges.',
  customUserRolesTitle: 'Custom User Roles',
  customUserRolesText: 'Adaptations to diverse organizational structures and requirements demanded high standards for user roles.',
  seamlessIntegrationTitle: 'Seamless Integration',
  seamlessIntegrationText: 'Additional features and integrations required special attention to data consistency, interoperability, and constant performance.',
  localizationTitle: 'Localization',
  localizationText: 'Regional customizations were necessary to ensure a consistent global user experience.',
  contentGovernanceTitle: 'Content Governance and Compliance',
  contentGovernanceText: 'Content management across various locations increased demands for regulatory compliance and uniformity.',
  contentSearchTitle: 'Product, Download & Website Content Search',
  contentSearchText: 'A powerful and flexible search function was needed, giving the Mitsubishi Electric team full control to meet specific requirements.',
  performanceSectionTitle: 'Performance and Security Requirements',
  performanceOptiomizationTitle: 'Performance Optimization',
  performanceOptiomizationText: 'Platform Performance Optimization for Enhanced User Experience and Fast Response Times.',
  securityTitle: 'Security and Access Control',
  securityText: 'Robust security measures and access controls across various regions were essential.',
  scalabilityTitle: 'Scalability',
  scalabilityText: 'Future growth planning was needed to handle increased data loads and user interactions.',
  userTrainingAndAdoptionTitle: 'User Training and Adoption',
  userTrainingAndAdoptionText: 'The introduction of the HCMS required extensive training and detailed user manuals.',
  interoperabilityTitle: 'Interoperability with External Systems',
  interoperabilityText: 'Ensuring compatibility and seamless interactions between the HCMS, the website, and external systems was critical.',
  sectionQuoteText1: 'The systems engineering approach of freshcells convinced us that they were the right partner for this project. Their ability to fully understand our complex system landscape and business environment was key to the project’s success. Together, we developed a customized solution that not only meets our need for a future-proof system but also delivers measurable results. We value freshcells as a reliable partner and look forward to continued collaboration.',
  implementationSectionTitle: 'The Implementation',
  implementationTitle: 'Scalable Platform with Modern Technology',
  implementationText: '<p>To tackle these challenges, a solution was developed based on cutting-edge technologies and systems engineering principles. A strategy focused on API- and microservice architecture for the web guided the software development, creating a scalable, global platform solution.</p><p>The headless content management system “Contentstack” was implemented to manage content across 21 countries, providing an intuitive content management experience. Custom functionalities were developed to meet specific client needs. A custom-built search function enhances the user experience for diverse target groups, completing the solution.</p><p>A single sign-on service ensures efficient login management, enhancing data security through access levels aligned with organizational structure and individual responsibilities. A dynamic hosting concept enabled the development of a scalable solution for seamless deployment and management of integrated systems. The integration of a CRM system allows Mitsubishi Electric Factory Automation to achieve process-optimized lead generation.</p>',
  outcomeSectionTitle: 'The Outcome',
  outcomeTitle: 'Efficient, Dynamic Platform Enhances B2B Capabilities',
  outcomeText: 'Mitsubishi Electric Factory Automation strengthens its operations on a future-proof and robust platform, achieving strategic goals and maximizing B2B capabilities:',
  outcomeListTextOne: "Direct control over all content enables centralized management, leading to more efficient and organized processes.",
  outcomeListTextTwo: 'The transition from a static to a dynamic system ensures flexible functionality, responsiveness, and adaptability—essential for meeting evolving demands and environments.',
  outcomeListTextThree: 'A tailored user management system allows all team members to coordinate their tasks efficiently. Clearly defined roles and permissions simplify collaboration and streamline workflows to better match users’ individual needs.',
  outcomeListTextFour: 'Process optimization enables faster and more efficient results. The ability to find and filter search results across all media in milliseconds greatly improves the speed and effectiveness of information retrieval, offering users convenient access to relevant content.',
  outcomeListTextFive: 'Additional systems, like a CRM, have been integrated to enable process automation for lead generation.',
  outcomeListTextSix: 'A custom-built, high-performance search feature allows for flexible searches across products, downloads, contacts, and website content, further enhancing the platform.',
  conclusionSectionTitle: 'Conclusion',
  conclusionTitle: 'Future-Proof Platform for Sustainable Success',
  conclusionText: '<p>Through successful collaboration with freshcells, Mitsubishi Electric Factory Automation EMEA has established a solid digital foundation to stay competitive in a rapidly evolving industrial landscape. The integrated solution not only addresses initial challenges but also enables ongoing innovation and adaptability.</p><p>This joint effort ensures a future-ready platform, positioning Mitsubishi Electric Factory Automation for continued success in technological advancements and strategic growth within industrial automation.</p>',
  conclusionBtnLabel: 'Get a free consultation'
})
